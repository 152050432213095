<template>
  <div>
    <label for="sort">{{ $t("spots.sort_by") }}</label>
    <select name="sort"
            id="sort"
            class="w-min bg-transparent font-bold text-theme-primary focus:outline-none text-left underline ml-1 appearance-none cursor-pointer"
            :value="modelValue"
            @change="handleChange">
      <option value="createdOn">{{ $t("spots.sort_by_date") }}</option>
      <option value="votes">{{ $t("spots.sort_by_votes") }}</option>
      <option value="comments">{{ $t("spots.sort_by_comments") }}</option>
    </select>
  </div>
</template>
<script setup lang="ts">
defineProps(["modelValue"]);
const emits = defineEmits(['update:modelValue'])

const handleChange = (evt: Event) => {
  emits('update:modelValue', (evt.target as HTMLInputElement).value);
}

</script>